
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

*{
    font-family: 'Montserrat', sans-serif;
}
/* antd overrides */
input {
    border-radius: 2px !important;
    border: 1px solid rgba(128, 128, 128, 0.5) !important;
    height: 40px !important;
}

input:focus {
    outline: none !important;
    box-shadow: none !important;
    border: 2px solid gray !important;
}

label {
    font-size: 15px !important;
    color: black !important;
}

.ant-form-item {
    margin: 10px 0 !important;
}

.ant-badge {
    font-size: 25px;
}

/* common styling */
.spinner-parent {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}
.spinner-border {
    width: 5rem;
    height: 5rem;
    color: white !important;
}

.page-title {
    font-size: 25px;
    color: rgba(0, 0, 0, 0.544);
}

header h3 {
    color: #fff;
}
header h3 span{
    font-size: 1rem;
}

.fade-title{
    font-size: 20px;
    color: rgba(0, 0, 0, 0.544);
}

.card-title {
    font-size: 1.2rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.card-mgy {
    margin-top: 20px;
    margin-bottom: 30px;
}

.normal-text {
    font-size: 1rem;
}

.card {
    box-shadow: 0 0 2px gray;
    border-radius: 0 !important;
}

/* authentication pages */

.authentication {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #178cb7;
}

.logo {
    height: 200px;
}

.authentication-form{
    width: 400px;
}

.authentication .card-title {
    font-size: 1.5rem;
    font-weight: bold;
    background-color: #d80633;
    color: #fff !important;
    max-width: max-content !important;
    padding: 7px 15px;
    margin-left: -40px;
    border-bottom-left-radius: 10px;
}

.primary-button {
    background-color: #178cb7 !important;
    border-color: #178cb7 !important;
    height: 40px !important;
    width: 100% !important;
    color: #fff !important;
    font-size: 16px !important;
    margin-top: 15px !important;
width: max-content !important;
}
.bt-submit{
    padding-bottom: 10px;
}
.anchor {
    color: black !important;
    font-size: 16px !important;
    cursor: pointer;
}

.anchor:hover {
    color:  #0d8ebd !important;
}

@media screen and (max-width: 768px){
    .card-title {
        font-size: 0.8rem;
    }
    .card-mgy {
    margin-top: 10px;
    margin-bottom: 10px;
}
.logo {
    height: 70px;
}
.anchor {
    color: #178cb7 !important;
    font-size: 12px !important;
}
}