.main {
    padding: 0px;
    height: 100vh;
}

.sidebar{
    background-color: #178cb7;
    border-radius: 5px;
    box-shadow: 0 0 2px gray;
    margin-right: 20px;
    min-height: 100%;
    padding: 10px;
}
.dashboard-page2 {
    width: 80vw;
    margin: 0 auto;
    padding: 1rem 1rem;
}

.sidebar-header {
    font-size: 30px;
    font-weight: bolder;
    color: rgba(255, 255, 255, 0.9);
    padding: 10px;
}

.header{
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 2px gray;
    margin-bottom: 20px;
    height: 10vh;
    width: 100%;
    display: flex ;
    align-items: center;
    justify-content: space-between;
}
.content {
    height: 100%;
    width: 100%;
}

.body {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 2px gray;
    /* height: 82vh; */
    width: 100%;
    padding: 15px;
}

.menu {
    margin-top: 80px;
    padding: 0 10px;
}

.menu-item {
    margin-top: 20px;
}

.menu-item a{
    color: rgba(255, 255, 255, 0.9);
    text-decoration: none;
    font-size: 18px;
    padding: 0 10px;
}

.menu-item i {
    color: rgba(255, 255, 255, 0.911);
    text-decoration: none;
    font-size: 24px;
    margin-top: 0;
    margin: 0 15px;
}

.active-menu-item {
    color: #fff;
    background-color: #023c51;
    padding: 5px;
    border-radius: 5px;
}

.header-action-icon {
    font-size: 30px;
    color: black;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 20px;
}
.notify {
    padding-right: 10px;
}

.form-container{ 
    padding-top: 0;
   margin-right: auto;
   margin-left: auto;
   width: 80%;
   
}
.profile-user {
    font-size: 14px !important;
}

.form-effizi {
    color: red !important;
}
.app-header{
    font-size: 22px;
    font-weight: bolder;
    color: #178cb7;
}
.pad-user {
    padding-right: 20px;
}

 /* Responsive queries */

@media screen and (max-width: 500px){
 .profile-user {
    font-size: 11px !important;
}
}
 @media screen and (max-width: 768px){
    .menu {
        padding: 0 5px;
    }
    .menu-item i{
        font-size: 18px;
        margin: 0 5px;
    }
    .menu-item a{
        font-size: 14px;
    }
    .sidebar-header {
        font-size: 20px;
        padding: 5px;
    }
    .app-header{
    font-size: 16px;
    font-weight: bolder;
    color: #010d11;
}
.header-action-icon{
    font-size: 20px;
    padding-left: 5px;
    padding-right: 10px !important;
}
.body {
    height: 100%;
}
.pad-user {
    padding-right: 10px;
    padding-left: 10px;
}

 }
